<script setup>
import {useMagicKeys} from "@vueuse/core";
import {watch} from "vue";
import {useRouter} from "vue-router";
const router = useRouter();
const keys=useMagicKeys();
const shortcuts= {
    home: keys['alt+h']
}
watch(shortcuts.home, (pressed) => {
    if (pressed) {
        router.push('/')
    }
})
</script>

<template>
  <router-view />
</template>

<style scoped></style>
